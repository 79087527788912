.ticket-contact-info {
  display: flex;
  flex-direction: column;

  &__header {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    &__name {
      flex-grow: 2;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }
  }
  &__body {
    padding: 0 24px;

    &__email {
      margin-top: 12px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-decoration-line: underline;
      color: #394154;
      &__text {
        margin-left: 5px;
      }
    }

    &__phone {
      margin-top: 12px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #000000;
      &__text {
        margin-left: 5px;
      }
    }
  }
}
