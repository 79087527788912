.file-preview {
  max-width: 150px;
  max-height: 90px;
  &__preview {
    cursor: pointer;
    background-color: gray;
    width: 150px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__footer {
    display: flex;
    align-items: center;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 8.65px;
    line-height: 10px;

    /* Titulos */

    color: #737373;
  }
}
