.profile__img {
  width: var(--profile-image-size);
  height: var(--profile-image-size);
  object-fit: cover;
  border-radius: 50%;
  background-color: rgb(78, 78, 78);
  margin: 5px;
  padding: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: var(--profile-image-size);
    height: var(--profile-image-size);
    object-fit: cover;
    border-radius: 50%;
    background-color: rgb(52, 52, 52);
  }
  &--small {
    --profile-image-size: 30px;
  }
  &--normal {
    --profile-image-size: 42px;
  }
  &--large {
    --profile-image-size: 60px;
  }
}
