// Custon select To export
.custom-select {
  padding: 10px;
  width: 100%;
  border: none;
  outline: none;
  line-height: 1.2em;
  background-color: #fff;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    bottom: 50%;
    margin-bottom: auto;
    margin-top: auto;
    right: 10px;
    display: inline-block;
    background: url("/assets/icons/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px;
    width: 15px;
    height: 15px;
    margin-left: 13px;
    transition: transform 0.3s ease;
  }

  &--open {
    &::after {
      transform: rotate(180deg);
    }
  }

  &-option__wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 1;

    height: 0;
    overflow: hidden;
    overflow-y: scroll;
    opacity: 0;
    transition: all 0.3s ease;

    &--active {
      height: max-content;
      max-height: 500px;
      opacity: 1;
    }
  }
  &-option {
    &__text {
      padding-left: 15px;
      white-space: nowrap;
    }
    &__selected {
      padding-right: 10px;
    }
    padding: 10px;
    cursor: pointer;
    &:hover {
      background-color: #eff1fd;
    }
  }
}
[class^="custom-select-option"] {
  word-break: keep-all;
  white-space: nowrap;
  text-overflow: ellipsis;
  // max-width: 90%;
  word-break: break-all;
  overflow: hidden;
}
