.ticket-actions {
  margin-top: 50px;
  &__header {
    &__title {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
    }
  }
  &__body {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    color: #000000;
  }
  &__field {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    &__title {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #000000;
      margin-bottom: 5px;
    }

    &__button {
      margin: 10px 0;
      align-self: flex-end;
      box-sizing: border-box;

      width: 84.91px;
      height: 19.46px;

      /* Rosa */

      background: #ea345f;
      mix-blend-mode: normal;
      /* Rosa */

      border: 0.884521px solid #ea345f;
      border-radius: 3.53808px;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      /* identical to box height */

      text-align: center;

      /* Blanco */

      color: #ffffff;
    }
  }
}
