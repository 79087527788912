.profile-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1em;
  padding-top: 20px;
  padding-left: 15px;
  flex-wrap: wrap;
  min-width: 400px;

  &__number {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    text-align: right;
    color: #000000;
    padding-right: 20px;
  }
  &-info {
    margin-left: 10px;
    flex-grow: 2;
    &__title {
      &__name {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 14.104px;
        line-height: 17px;
        color: #000000;
      }
      &__date {
        padding-left: 20px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 11.2832px;
        line-height: 13px;
        color: #394154;
      }
    }

    &__description {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14.104px;
      line-height: 17px;
      color: #394154;
    }
  }
}
