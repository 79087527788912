/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 1px grey; */
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d1d1d1;
  /* background: #404762; */
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d1d1d1;
}

body * {
  font-family: "Rubik" !important;
}

.class-input-focused:focus {
  border-color: #000000 !important;
}

div {
  font-family: "Rubik";
}

button {
  font-family: "Rubik" !important;

  box-shadow: none !important;
}

.tooltip-class-agent {
  background-color: #ffffff !important;
  width: 500px;
  border: 1px solid #afb8cd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.MuiFormHelperText-root {
  color: red !important;
}

.avatar-editor {
  width: 100%;
}

.css-1psng7p-MuiTablePagination-spacer {
  -webkit-flex: none !important;
  flex: none !important;
}

.MuiTablePagination-toolbar {
  justify-content: center;
}

.MuiTablePagination-spacer {
  -webkit-flex: none !important;
  flex: none !important;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/font/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Light";
  src: url("./assets/font/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik";
  src: url("./assets/font/Rubik-VariableFont_wght.ttf") format("truetype");
}

.message-img-vl-agent:hover {
  cursor: pointer;
}

.item-chat-vl-agent:hover {
  cursor: pointer;
  background-color: rgba(27, 7, 121, 0.11) !important;
}

.img-vl-gallery:hover {
  cursor: pointer !important;
}

._1Yplu {
  display: none;
}
._3bC73 {
  text-align: center;
}

._3wi1g {
  width: 150px;
}

.MuiList-padding {
  padding-bottom: 0px !important;
}

.classRowHistoryVL:hover {
  /* cursor: pointer; */
}

.status-agent:hover {
  cursor: pointer;
}
.MuiOutlinedInput-notchedOutline {
  border-color: #ffffff !important;
}

.MuiDialog-paper {
  /* border-radius: 12px !important; */
}

.css-hppdow {
  border-radius: 12px !important;
}
