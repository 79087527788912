.ticket-details {
  &-files {
    border-top: 1px solid #dadada;
    margin-top: 20px;
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  &-table {
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 20px 60px;
    overflow-y: scroll;
    flex-grow: 2;
    &__row {
      display: grid;
      grid-template-columns: minmax(200px, 300px) 1fr;
      margin-top: 10px;
    }
    &__key {
      background-color: #fff;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      word-break: break-all;
    }
    &__value {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      word-break: break-all;
      color: #000000;
    }
  }
  &-messages {
    padding: 20px 60px;
    overflow-y: auto;
    &__message {
      border-top: 1px solid #dadada;

      &__content {
        font-family: "Rubik";
        // font-style: normal;
        // font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        color: #000000;
      }
    }
  }
}
