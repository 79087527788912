.contact-form-new {
  background: #ffffff;
  padding: 12px;
  box-shadow: 0 0 0.1rem #00000033;
  display: flex;
  flex-direction: column;
  gap: 6px;
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__label {
      font-weight: 700;
      font-size: 1rem;
      color: #000000;
      text-align: center;
      padding: 0;
      margin: 0;
    }
  }
  &__body {
  }
}

.IdentifierBox,
.BoxInfo {
  &__msj {
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
    color: #000000;
  }
}

.AlertAtention {
  --bg: #fbffcd;
  --colorIcon: #ebe14b;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 10px;
  box-shadow: 0 0 0.3rem #00000033;
  padding: 10px;
  //   background: var(--bg);
  border-radius: 6px;
  &__icon {
    flex-shrink: 0;
    width: 30px;
    aspect-ratio: 1 / 1;
    display: grid;
    place-items: center;
    color: var(--colorIcon);
  }
  &__info {
    display: flex;
    flex-direction: column;
    gap: 6px;
    &__text {
      margin: 0;
      padding: 0;
      font-size: 0.8rem;
      color: #000000;
    }
    &__btnAction {
      min-width: 200px;
      max-width: 200px;
      margin: 0 auto;
      border: none;
      background: #ebe14b;
      color: #ffffff;
      padding: 7px 8px;
      border-radius: 33px;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 250ms linear;
      &:active {
        transform: scale(0.9);
      }
    }
  }
}

.formCurrent {
  display: flex;
  flex-direction: column;
  gap: 6px;

  &__form {
    &__list {
      margin: 0 auto;
      padding: 0;
      list-style: none;
      padding: 6px;
      display: flex;
      flex-direction: column;
      gap: 6px;
      max-width: 350px;
      &__item {
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    &__btn {
      min-width: 200px;
      border: none;
      background: #ea345f;
      color: #ffffff;
      padding: 7px 8px;
      border-radius: 33px;
      font-size: 1rem;
      font-weight: 500;
      transition: all 250ms linear;
      &:not(&:disabled) {
        cursor: pointer;
        &:active {
          transform: scale(0.9);
        }
      }
      &:disabled {
        background: #75757588;
        cursor: not-allowed;
      }
    }
  }
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 6px;

  &__label {
    font-weight: 500;
    font-size: 0.8rem;
    color: #757575;
    margin: 0;
    padding: 0;
  }
  &__inputs {
    display: flex;
    align-items: center;
    gap: 6px;
    &__general {
      width: 100%;
      min-height: 40px;
      outline: none;
      border: none;
      border-radius: 40px;
      background: #00000012;
      padding: 0px 15px;
      &::placeholder {
        font-weight: 500;
        color: #75757599;
      }
    }
    &__wrapperSelect {
      position: relative;
      width: 30%;
    }

    &__phoneCode {
      width: 100%;
      appearance: none;
      min-height: 40px;
      outline: none;
      border: none;
      border-radius: 40px;
      background: #00000012;
      padding: 0px 15px;
      &::placeholder {
        font-weight: 500;
        color: #75757599;
      }

      &::before,
      &::after {
        --size: 0.3rem;
        position: absolute;
        content: "";
        right: 1rem;
        pointer-events: none;
      }

      &::before {
        border-left: var(--size) solid transparent;
        border-right: var(--size) solid transparent;
        border-bottom: var(--size) solid #757575;
        top: 40%;
      }

      &::after {
        border-left: var(--size) solid transparent;
        border-right: var(--size) solid transparent;
        border-top: var(--size) solid #757575;
        top: 55%;
      }
      &__icon {
        position: absolute;
        top: calc(50% - 10px);
        right: 10px;
        width: 20px;
        aspect-ratio: 1 / 1;
        pointer-events: none;
        color: #000000;
      }
    }
    &__phoneNumber {
      width: 70%;
      min-height: 40px;
      outline: none;
      border: none;
      border-radius: 40px;
      background: #00000012;
      padding: 0px 15px;
      &::placeholder {
        font-weight: 500;
        color: #75757599;
      }
    }
  }
  &__error {
    font-size: 0.7rem;
    font-weight: 500;
    color: red;
    margin: 0;
    padding: 0;
  }
}

.IdentifierBox {
  &__form {
    &__list {
      margin: 0 auto;
      padding: 0;
      list-style: none;
      padding: 6px;
      display: flex;
      flex-direction: column;
      gap: 6px;
      max-width: 350px;
    }
    &__actions {
      margin-top: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      &__btn {
        min-width: 200px;
        border: none;
        background: #ea345f;
        color: #ffffff;
        padding: 7px 8px;
        border-radius: 33px;
        font-size: 1rem;
        font-weight: 500;
        transition: all 250ms linear;
        &:not(&:disabled) {
          cursor: pointer;
          &:active {
            transform: scale(0.9);
          }
        }
        &:disabled {
          background: #75757588;
          cursor: not-allowed;
        }
      }
    }
  }
}

.BoxInfo {
  &__form {
    &__list {
      margin: 0 auto;
      padding: 0;
      list-style: none;
      padding: 6px;
      display: flex;
      flex-direction: column;
      gap: 6px;
      max-width: 350px;
    }
    &__actions {
      margin-top: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 12px;
      &__btn {
        min-width: 200px;
        border: none;
        border: 1px solid #ea345f;
        background: #ea345f;
        color: #ffffff;
        padding: 7px 8px;
        border-radius: 33px;
        font-size: 1rem;
        font-weight: 500;
        transition: all 250ms linear;

        &.alt {
          border: 1px solid #ea345f;
          background: #ffffff;
          color: #ea345f;
        }

        &:not(&:disabled) {
          cursor: pointer;
          &:active {
            transform: scale(0.9);
          }
        }
        &:disabled {
          border: 1px solid #757575;
          background: #757575;
          cursor: not-allowed;

          &.alt {
            border: 1px solid #75757588;
            background: #ffffff;
            color: #75757588;
          }
        }
      }
    }
  }
}

.vl-overlay {
  position: fixed;
  z-index: 1201;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  display: grid;
  place-content: center;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  transition: all 250ms linear;
  &.active {
    pointer-events: all;
    opacity: 1;
    .vl-dialog {
      transform: scale(1);
    }
  }
}

.vl-dialog {
  transform: scale(0);
  position: static;
  border: none;
  border-radius: 6px;
  border-radius: 0 0 0.3rem #75757533;
  min-width: 200px;
  max-width: 450px;
  max-height: 450px;
  // aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  padding: 0px;
  overflow: hidden;
  background: #ffffff;
  transition: all 300ms ease;
  &__header {
    display: grid;
    grid-template-columns: 1fr 30px;
    align-items: center;
    column-gap: 5px;
    padding: 6px;
    background: #ea345f;
    &__title {
      margin: 0;
      padding: 0;
      color: #ffffff;
      font-size: 18px;
      text-align: center;
    }
    &__btnClose {
      border: none;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      aspect-ratio: 1 / 1;
      margin: 0;
      padding: 0;
      color: #ffffff;
      cursor: pointer;
      transition: all 250ms linear;
      &:active {
        transform: scale(0.9);
      }
    }
  }
  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
    padding: 6px 12px 12px;
    &__btnSuccess,
    &__btnCancel {
      min-width: 200px;
      border: none;
      border: 1px solid #ea345f;
      background: #ea345f;
      color: #ffffff;
      padding: 7px 8px;
      border-radius: 33px;
      font-size: 1rem;
      font-weight: 500;
      transition: all 250ms linear;
      &:not(&:disabled) {
        cursor: pointer;
        &:active {
          transform: scale(0.9);
        }
      }
    }
    &__btnCancel {
      border: 1px solid #ea345f;
      background: #ffffff;
      color: #ea345f;
    }
  }
  &__body {
    flex-grow: 1;
    padding: 6px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 6px 16px;
    &__text {
      margin: 0;
      padding: 0;
      text-align: left;
      color: #757575;
      &.w500 {
        font-weight: 500;
      }
    }
  }
}
