.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #1b0845;
  height: 60px;
  width: 100%;
  //   max-width: 100vw;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 100px;
  padding-right: 30px;
  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
  }
  &__actions {
    box-sizing: border-box;
    appearance: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
  }
}
