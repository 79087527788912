//paginator
.ticket-list-paginator {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &__arrow {
    &__left {
      cursor: pointer;
      margin-left: 1em;
      transform: rotate(180deg);
    }
    &__right {
      cursor: pointer;
      margin-left: 7px;
    }

    &--disabled {
      opacity: 0.5;
    }
  }
}
