.wrapper-cardCard-empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 20% 0 0 0;
  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__icon {
      width: 100px;
      aspect-ratio: 1 / 1;
      object-fit: contain;
    }
    &__text {
      font-size: 1.2rem;
      font-weight: 500;
      color: #757575;
      text-align: center;
      padding: 0;
      margin: 0;
    }
  }
}

.cardChat {
  margin: 10px auto;
  width: 95%;
  box-shadow: 0 0 0.3rem #00000033;
  padding: 6px;
  display: grid;
  grid-template-columns: 40px 1fr 60px;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  min-height: 80px;
  cursor: pointer;
  background: #ffffff;
  &.selected {
    background: #1b07791c;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    &__icon {
      width: 40px;
      aspect-ratio: 1 / 1;
      object-fit: contain;
      border-radius: 50%;
      box-shadow: 0 0 0.3rem #00000033;
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &__contact {
      font-size: 1rem;
      font-weight: 700;
      color: #394154;
      text-align: left;
      padding: 0;
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &__ability {
      font-size: 0.8rem;
      font-weight: 500;
      color: #757575;
      text-align: left;
      padding: 0;
      margin: 0;
    }
  }

  &__extra {
    display: flex;
    flex-direction: column;
    &__hours {
      font-size: 0.7rem;
      font-weight: 500;
      color: #757575;
      text-align: right;
      padding: 0;
      margin: 0;
    }
    &__notifications {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      column-gap: 6px;

      &__msj {
        width: 20px;
        height: 20px;
        font-size: 0.7rem;
        line-height: 0;
        font-weight: 500;
        color: #ffffff;
        background: #ff0000;
        text-align: center;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 0.3rem #00000033;
        border-radius: 50%;
      }
      &__transfert {
        width: 20px;
        height: 20px;
        aspect-ratio: 1 / 1;
        &__icon {
          width: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
    }
  }
}
