%row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

%ellipsis {
  line-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: "Open Sans";
  max-width: 75%;
}

.ticket-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 5px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #f5f5f5;
  }

  &__header {
    &__name {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #17191e;
    }
    &__date {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-align: right;
      color: #afb8cd;
    }
  }
  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__number {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #17191e;
    }
  }

  &__footer {
    &__summary {
      @extend %ellipsis;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #17191e;
      display: block;
    }
    &__description {
      @extend %ellipsis;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #848fab;
      display: block;
    }
  }

  &__header,
  &__body {
    @extend %row;
  }
}

.ticket-list {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow-y: scroll;
  padding: 10px;
  background-color: #e6e7f3;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ticket-search {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 300px;
  &::before {
    content: "";
    display: inline-block;
    background: url("/assets/icons/search.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px;
    width: 15px;
    height: 15px;
    margin-left: 13px;
  }
  &__input {
    width: 100%;
    max-width: 285px;
    border: none;
    outline: none;
    line-height: 2em;
    background-color: #fff;
    border-radius: 3px;
  }

  &__refresh {
    cursor: pointer;
    border: none;
    background-color: transparent;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ticket-filter {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  // padding: 10px;
  border-radius: 3px;
  &__select {
  }
}
