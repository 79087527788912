.ticket-input {
  padding: {
    top: 20px;
    bottom: 20px;
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-self: flex-end;
  border-top: 1px solid #d6d8e8;

  &__attach {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__input {
      display: none;
    }
    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;
      margin-right: 10px;
      background-color: #f4f4f4;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }
  &__client {
    padding: 0 60px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #17191e;
  }

  &__content {
    background-color: #fff;
    display: inline-block;
    padding: 10px 60px;
    max-height: 400px;
    overflow-y: scroll;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    white-space: pre;

    color: #17191e;

    word-break: break-all;
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;
    width: 100%;
    max-width: 900px;
    overflow: hidden;

    &:focus {
      outline: none;
    }
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    padding: 10px;
    padding-bottom: 0;
    border: {
      top: 1px solid #d6d8e8;
    }
    &__button {
      cursor: pointer;
      outline: none;
      color: white;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      background: #4a27ed;
      border-radius: 3.18882px;
      padding: 5px;
      border: none;
      min-width: 130px;
    }
  }
  &__chip__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    padding: 10px 60px;

    // padding: 10px 60px;
  }
  &__chip {
    background: #f5f5f5;
    mix-blend-mode: normal;
    border-radius: 50px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    color: #1b0845;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    margin: 5px;
    // span {
    //   display: inline-block;
    // }
    &__remove {
      margin-left: 10px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 12px;
      height: 12px;
    }
  }
  & [placeholder]:empty::before {
    content: attr(placeholder);
    color: rgb(172, 172, 172);
    font-weight: 200;
  }

  & [placeholder]:empty:focus::before {
    content: "";
  }
}
