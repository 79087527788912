.list-document {
  margin-top: 6px;
  background: #ffffff;
  padding: 12px;
  box-shadow: 0 0 0.1rem #00000033;
  display: flex;
  flex-direction: column;
  gap: 6px;
  overflow: hidden;
  &__title {
    font-weight: 700;
    font-size: 1rem;
    color: #000000;
    text-align: center;
    padding: 0;
    margin: 0;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 0;
    margin: 0;
    list-style: none;
    overflow: hidden;
    border-top: 1px solid #dadada;
    padding-top: 6px;
    padding-left: 6px;
    padding-right: 6px;
    // box-shadow: 0 0 0.1rem #00000033;
    &__item {
      margin: 0;
      &__file {
        cursor: pointer;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        color: #757575;
        transform: scale(0.9);
        transition: all 250ms linear;
        gap: 6px;
        &:hover {
          transform: scale(1);
        }

        &__primary {
          appearance: none;
          display: grid;
          grid-template-columns: 30px 1fr 30px;
          align-items: center;
          color: currentColor;
          padding: 6px 1rem;
          border-radius: 24px;
          box-shadow: 0 0 0.3rem #00000033;
          box-sizing: border-box;

          &__icon {
            justify-self: center;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            aspect-ratio: 1 / 1;
          }
          &__name {
            margin: 0;
            padding: 0;
            font-size: 1rem;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &__download {
            justify-self: center;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            aspect-ratio: 1 / 1;
          }
        }
        &__extra {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 1rem;
          &__type {
            color: #757575;
            font-size: 0.6rem;
            font-weight: 500;
            text-align: left;
          }
          &__date {
            color: #757575;
            font-size: 0.6rem;
            font-weight: 500;
            text-align: right;
          }
        }
      }
      &__text {
        margin: 0;
        padding: 0;
        font-size: 1rem;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
