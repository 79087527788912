@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");

#sidebar-vl {
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 1px grey; */
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #404762;
    /* background: #404762; */
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #404762;
  }
}

#scrollbar-vl {
  /* width */
  ::-webkit-scrollbar {
    width: 1px;
    height: 1px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 1px grey; */
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #404762;
    /* background: #404762; */
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #404762;
  }
}
// Set Input autoComplete

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #f6f6fc inset;
  font-family: "Rubik";
  font-style: normal;
}
body {
  font-size: 1.6rem;
  font-family: var(--font-primary, "Rubik", sans-serif) !important;
  overflow-y: hidden;
}
